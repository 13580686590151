
    import __i18nConfig from '@next-translate-root/i18n'
    import __loadNamespaces from 'next-translate/loadNamespaces'
    function Error() {
    return (<div className="flex w-full justify-center items-center">
      <span className="font-bold text-2xl font-tk-museo-sans">
          <span className="font-light">{"Something went wrong"}</span>
      </span>
    </div>);
}
export default Error;

    async function __Next_Translate__getStaticProps__194fab4b132__(ctx) {
      
      return {
        
        
        props: {
          
          ...(await __loadNamespaces({
            ...ctx,
            ...__i18nConfig,
            pathname: '/_error',
            loaderName: 'getStaticProps',
            loadLocaleFrom: __i18nConfig && __i18nConfig.loadLocaleFrom || ((l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default)),
          }))
        }
      }
    }
    export { __Next_Translate__getStaticProps__194fab4b132__ as getStaticProps }
  